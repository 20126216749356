import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase.js';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import './Friends.css';

const Friends = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestedFriends, setSuggestedFriends] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        setCurrentUser(userDoc.data());
        fetchSuggestedFriends(userDoc.data());
      }
    } catch (error) {
      console.error('Kullanıcı bilgileri yüklenirken hata:', error);
      setError('Kullanıcı bilgileri yüklenirken bir hata oluştu');
    }
  };

  const searchUsers = async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('nickname', '>=', query), where('nickname', '<=', query + '\uf8ff'));
      const querySnapshot = await getDocs(q);
      const results = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(user => user.id !== auth.currentUser?.uid);
      setSearchResults(results);
    } catch (error) {
      console.error('Kullanıcı arama hatası:', error);
      setError('Kullanıcılar aranırken bir hata oluştu');
    }
  };

  const fetchSuggestedFriends = async (userData) => {
    try {
      if (!userData?.preferences?.genres?.length) {
        setLoading(false);
        return;
      }

      const usersRef = collection(db, 'users');
      const genres = userData.preferences.genres.slice(0, 3);
      
      // Her bir tür için ayrı sorgu yapıp sonuçları birleştir
      const queries = genres.map(genre => 
        query(usersRef, where('preferences.genres', 'array-contains', genre))
      );

      const results = new Set();
      for (const q of queries) {
        const querySnapshot = await getDocs(q);
        querySnapshot.docs.forEach(doc => {
          if (doc.id !== auth.currentUser?.uid) {
            results.add(JSON.stringify({ id: doc.id, ...doc.data() }));
          }
        });
      }

      const suggestions = Array.from(results)
        .map(user => JSON.parse(user))
        .slice(0, 5);

      setSuggestedFriends(suggestions);
    } catch (error) {
      console.error('Önerilen arkadaşlar yüklenirken hata:', error);
      setError('Önerilen arkadaşlar yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const addFriend = async (userId) => {
    try {
      const currentUserId = auth.currentUser.uid;
      const friendRequestRef = doc(db, 'friendRequests', `${currentUserId}_${userId}`);
      
      await setDoc(friendRequestRef, {
        senderId: currentUserId,
        receiverId: userId,
        status: 'pending',
        createdAt: new Date()
      });

      setSuccess('Arkadaşlık isteği gönderildi');
    } catch (error) {
      console.error('Arkadaş ekleme hatası:', error);
      setError('Arkadaş eklenirken bir hata oluştu');
    }
  };

  const removeFriend = async (friendId) => {
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const friendRef = doc(db, 'users', friendId);

      await updateDoc(userRef, {
        friends: arrayRemove(friendId)
      });

      await updateDoc(friendRef, {
        friends: arrayRemove(currentUser.uid)
      });
    } catch (err) {
      console.error('Arkadaş silme hatası:', err);
      setError('Arkadaş silinirken bir hata oluştu.');
    }
  };

  return (
    <div className="friends-container">
      <div className="friends-header">
        <h2>Arkadaşlar</h2>
        <div className="search-box">
          <input
            type="text"
            placeholder="Kullanıcı ara..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              searchUsers(e.target.value);
            }}
          />
          <i className="fas fa-search"></i>
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}

      {/* Arama Sonuçları */}
      {searchResults.length > 0 && (
        <div className="search-results">
          <h3>Arama Sonuçları</h3>
          <div className="users-grid">
            {searchResults.map(user => (
              <div key={user.id} className="user-card">
                <div className="user-info">
                  <img src={user.profilePicture || 'https://via.placeholder.com/50'} alt={user.nickname} />
                  <div>
                    <h4>{user.nickname}</h4>
                    <p>{user.city || 'Şehir belirtilmemiş'}</p>
                  </div>
                </div>
                <button 
                  className="add-friend-btn"
                  onClick={() => addFriend(user.id)}
                >
                  Arkadaş Ekle
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Önerilen Arkadaşlar */}
      {!loading && suggestedFriends.length > 0 && (
        <div className="suggested-friends">
          <h3>Benzer Zevklere Sahip Kullanıcılar</h3>
          <div className="users-grid">
            {suggestedFriends.map(user => (
              <div key={user.id} className="user-card">
                <div className="user-info">
                  <img src={user.profilePicture || 'https://via.placeholder.com/50'} alt={user.nickname} />
                  <div>
                    <h4>{user.nickname}</h4>
                    <p>{user.city || 'Şehir belirtilmemiş'}</p>
                  </div>
                </div>
                <button 
                  className="add-friend-btn"
                  onClick={() => addFriend(user.id)}
                >
                  Arkadaş Ekle
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {loading && <div className="loading">Yükleniyor...</div>}
    </div>
  );
};

export default Friends; 