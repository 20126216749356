import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase.js";
import "./Login.css";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleEmailPasswordAuth = async () => {
    if (!email || !password) {
      setErrorMessage("Please fill in all fields!");
      return;
    }
    
    setLoading(true);
    setErrorMessage("");
    
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (error) {
      setErrorMessage("Invalid email or password!");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate("/");
    } catch (error) {
      setErrorMessage("Google sign in failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-page-wrapper">
      <div className="auth-container">
        <h1 className="brand-title">WATCHIFY</h1>
        <h2 className="welcome-title">Welcome Back!</h2>
        <p className="welcome-subtitle">
          Sign in to continue your movie journey
        </p>

        {errorMessage && <div className="alert">{errorMessage}</div>}

        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={handleEmailPasswordAuth}
            disabled={loading}
          >
            {loading ? "Signing In..." : "Sign In"}
          </button>


          <p className="toggle-auth" onClick={() => navigate("/register")}>
            New to Watchify? Create Account
          </p>
        </form>

        <p className="auth-footer">
          © 2025 Watchify. Crafted with ❤️ by BalTech.
        </p>
      </div>
    </div>
  );
};

export default Login;
