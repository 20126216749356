import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Chatbot from './Chatbot.js';
import './Help.css';

const Help = () => {
  const [activeCategory, setActiveCategory] = useState('general');
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedFaqs, setExpandedFaqs] = useState({});

  const faqCategories = [
    { id: 'general', name: 'General', icon: 'fa-solid fa-circle-info' },
    { id: 'account', name: 'Account & Billing', icon: 'fa-solid fa-user-circle' },
    { id: 'streaming', name: 'Streaming & Playback', icon: 'fa-solid fa-play-circle' },
    { id: 'devices', name: 'Devices & Apps', icon: 'fa-solid fa-mobile-screen' },
    { id: 'content', name: 'Content & Features', icon: 'fa-solid fa-film' },
    { id: 'troubleshooting', name: 'Troubleshooting', icon: 'fa-solid fa-wrench' }
  ];

  const faqData = {
    general: [
      {
        id: 'general-1',
        question: 'What is Watchify?',
        answer: 'Watchify is a streaming service that offers you a tailored recommendation for your tastes. You can watch as much as you want, whenever you want – all for one low monthly price. There\'s always something new to discover, and new TV shows and movies are added every week!'
      },
      {
        id: 'general-2',
        question: 'How much does Watchify cost?',
        answer: 'Watch Watchify on your smartphone, tablet, Smart TV, laptop, or streaming device, all for one fixed monthly fee. Plans range from $8.99 to $15.99 a month. No extra costs, no contracts.'
      },
      {
        id: 'general-3',
        question: 'Where can I watch?',
        answer: 'Watch anywhere, anytime. Sign in with your Watchify account to watch instantly on the web at watchify.com from your personal computer or on any internet-connected device including smart TVs, smartphones, tablets, streaming media players and game consoles.'
      },
      {
        id: 'general-4',
        question: 'How do I cancel?',
        answer: 'Watchify is flexible. There are no pesky contracts and no commitments. You can easily cancel your account online in two clicks. There are no cancellation fees – start or stop your account anytime.'
      },
      {
        id: 'general-5',
        question: 'What can I watch on Watchify?',
        answer: 'Watchify has an extensive library of feature films, documentaries, TV shows, anime, award-winning Watchify originals, and more. Watch as much as you want, anytime you want.'
      }
    ],
    account: [
      {
        id: 'account-1',
        question: 'How do I reset my password?',
        answer: 'To reset your password, visit the Watchify sign-in page and click on the "Forgot Password" link. You will be prompted to enter your email address, and we will send you instructions to reset your password.'
      },
      {
        id: 'account-2',
        question: 'How do I update my payment method?',
        answer: 'To update your payment method, sign in to your Watchify account, go to "Account Settings," select "Payment Methods," and click on "Edit" or "Add Payment Method" to update your information.'
      },
      {
        id: 'account-3',
        question: 'Can I share my Watchify account with others?',
        answer: 'No, every Watchify plan lets you create one profile.Because it is a tailored recommendation system. It has to be unique.'
      },
      {
        id: 'account-4',
        question: 'When will I be billed?',
        answer: 'Your Watchify subscription is charged at the beginning of your billing cycle and automatically renews each month until you cancel. You can find your next billing date under "Account Settings."'
      },
      {
        id: 'account-5',
        question: 'How do I change my email address?',
        answer: 'To change your email address, sign in to your Watchify account, go to "Account Settings," select "Personal Info," and update your email address. You will receive a verification email to confirm the change.'
      }
    ],
    streaming: [
      {
        id: 'streaming-1',
        question: 'What is the recommended internet speed for streaming?',
        answer: 'For SD quality streaming, we recommend a minimum internet connection speed of 3 Mbps. For HD quality, we recommend at least 5 Mbps. For 4K Ultra HD quality, we recommend at least 25 Mbps.'
      },
      {
        id: 'streaming-2',
        question: 'How do I adjust video quality settings?',
        answer: 'To adjust video quality settings, sign in to your Watchify account, go to "Account Settings," select "Playback Settings," and choose your preferred data usage option. You can also adjust quality during playback by selecting the settings icon in the player.'
      },
      {
        id: 'streaming-3',
        question: 'Can I download content to watch offline?',
        answer: 'Yes, many titles on Watchify are available for download on our mobile apps. Look for the download icon next to titles that are available for offline viewing. Downloaded content can be watched without an internet connection.'
      },
      {
        id: 'streaming-4',
        question: 'Why does my video keep buffering?',
        answer: 'Buffering may occur due to slow internet speeds, network congestion, or too many devices using your network simultaneously. Try closing other apps and browsers, moving closer to your Wi-Fi router, or connecting directly via Ethernet for a more stable connection.'
      },
      {
        id: 'streaming-5',
        question: 'How do I enable or disable subtitles?',
        answer: 'During playback, click on the dialog box icon in the player controls to access subtitle options. From there, you can select your preferred language or turn subtitles off completely.'
      }
    ],
    devices: [
      {
        id: 'devices-1',
        question: 'Which devices support Watchify?',
        answer: 'Watchify is compatible with most internet-connected devices, including smart TVs (Samsung, LG, Sony, etc.), streaming media players (Roku, Apple TV, Chromecast, etc.), game consoles (PlayStation, Xbox), smartphones, tablets, and computers.'
      },
      {
        id: 'devices-2',
        question: 'How do I install Watchify on my device?',
        answer: 'We are working on that! Now you can only watch on the web.'
      },
      {
        id: 'devices-3',
        question: 'Why can\'t I sign in on my device?',
        answer: 'If you\'re having trouble signing in, make sure you\'re using the correct email and password. If you\'ve forgotten your password, use the "Forgot Password" option. Also, ensure your device has the latest Watchify app version and operating system updates.'
      },
      {
        id: 'devices-4',
        question: 'How many devices can I register to my account?',
        answer: 'You can register an unlimited number of devices to your Watchify account, but the number of devices that can stream simultaneously depends on your subscription plan (1 for Basic, 2 for Standard, 4 for Premium).'
      },
      
    ],
    content: [
      {
        id: 'content-1',
        question: 'How often is new content added to Watchify?',
        answer: 'New movies, TV shows, and Watchify Originals are added to the platform weekly. We continually refresh our content library to provide you with the best entertainment options.'
      },
      {
        id: 'content-2',
        question: 'How do I request a specific movie or show?',
        answer: 'While we can\'t guarantee that we\'ll add specific titles, we value your feedback. You can request titles by visiting our "Content Request" form in the "Help" section of our website or app.'
      },
      {
        id: 'content-3',
        question: 'Why is certain content not available in my region?',
        answer: 'Content availability varies by region due to licensing agreements. We work hard to offer a consistent library across regions, but some titles may be exclusive to certain countries due to existing distribution agreements.'
      },
      {
        id: 'content-4',
        question: 'How do I access Watchify Originals?',
        answer: 'Watchify Originals are integrated into your main browsing experience. You can also find a dedicated "Watchify Originals" category in the browse menu to explore our exclusive content.'
      },
      {
        id: 'content-5',
        question: 'Can I watch live TV on Watchify?',
        answer: 'Watchify primarily offers on-demand content and does not include traditional live TV channels. However, some events or shows may be streamed live as special features.'
      }
    ],
    troubleshooting: [
      {
        id: 'troubleshooting-1',
        question: 'What should I do if Watchify isn\'t working?',
        answer: 'If you\'re experiencing issues, try these steps: 1) Check your internet connection, 2) Restart your device, 3) Update the Watchify app, 4) Clear the app cache, 5) Reinstall the app. If problems persist, contact our customer support.'
      },
      {
        id: 'troubleshooting-2',
        question: 'Why am I seeing an error code?',
        answer: 'Error codes help identify specific issues. You can search for your error code in our Help Center for targeted troubleshooting steps. Common error codes relate to network connectivity, device compatibility, or account issues.'
      },
      {
        id: 'troubleshooting-3',
        question: 'How do I fix audio/video sync issues?',
        answer: 'For audio/video sync issues, try: 1) Pause and restart the video, 2) Check your internet speed, 3) Close other applications using bandwidth, 4) Restart your device, 5) Clear the app cache, or 6) Try a different browser or device.'
      },
      {
        id: 'troubleshooting-4',
        question: 'Why is the video quality poor?',
        answer: 'Poor video quality may be due to slow internet speeds. Check your connection, close other bandwidth-intensive applications, and ensure your data usage settings are set to "High" in your account preferences.'
      },
      {
        id: 'troubleshooting-5',
        question: 'How do I report a technical issue?',
        answer: 'To report a technical issue, go to the "Help" section in your account, select "Report a Problem," and provide details about the issue you\'re experiencing. Include the device you\'re using, the title you were watching, and any error messages you received.'
      }
    ]
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleFaq = (questionId) => {
    setExpandedFaqs(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };

  const filteredFaqs = searchQuery 
    ? Object.values(faqData).flat().filter(faq => 
        faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : faqData[activeCategory];

  return (
    <div className="help-container">
      {/* Hero Section */}
      <section className="help-hero">
        <div className="help-hero-content">
          <h1>How can we help you?</h1>
          <p>Find answers to common questions and learn how to get the most out of your Watchify experience.</p>
          <div className="search-container">
            <input 
              type="text" 
              placeholder="Search for help topics..." 
              value={searchQuery}
              onChange={handleSearch}
              className="search-input"
            />
            <button className="search-button">
              <i className="fa-solid fa-search"></i>
            </button>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="help-content">
        <div className="faq-categories">
          {faqCategories.map(category => (
            <button 
              key={category.id}
              className={`category-button ${activeCategory === category.id && !searchQuery ? 'active' : ''}`}
              onClick={() => {
                setActiveCategory(category.id);
                setSearchQuery('');
              }}
            >
              <i className={category.icon}></i>
              <span>{category.name}</span>
            </button>
          ))}
        </div>

        <div className="faq-section">
          {searchQuery && (
            <div className="search-results-header">
              <h2>Search Results</h2>
              <p>{filteredFaqs.length} results found for "{searchQuery}"</p>
              <button className="clear-search" onClick={() => setSearchQuery('')}>
                Clear Search <i className="fa-solid fa-times"></i>
              </button>
            </div>
          )}

          {!searchQuery && (
            <h2 className="faq-category-title">
              <i className={faqCategories.find(c => c.id === activeCategory)?.icon}></i>
              {faqCategories.find(c => c.id === activeCategory)?.name} FAQs
            </h2>
          )}

          <div className="faq-list">
            {(searchQuery ? Object.values(faqData).flat() : faqData[activeCategory])?.map((faq) => (
              <div 
                key={faq.id} 
                className={`faq-item ${expandedFaqs[faq.id] ? 'active' : ''}`}
              >
                <div 
                  className="faq-question"
                  onClick={() => toggleFaq(faq.id)}
                >
                  <h3>{faq.question}</h3>
                  <i className="fas fa-chevron-down"></i>
                </div>
                <div className={`faq-answer ${expandedFaqs[faq.id] ? 'show' : ''}`}>
                  {faq.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="help-contact">
        <div className="contact-card">
          <div className="contact-icon">
            <i className="fa-solid fa-headset"></i>
          </div>
          <h3>Still need help?</h3>
          <p>Our support team is available 24/7 to assist you with any issues you may be experiencing.</p>
          <Link to="/contact" className="contact-button">Contact Support</Link>
        </div>
        <div className="contact-card">
          <div className="contact-icon">
            <i className="fa-solid fa-comments"></i>
          </div>
          <h3>Community Forums</h3>
          <p>Join discussions with other Watchify users, share tips, and get help from the community.</p>
          <a href="#" className="contact-button">Visit Forums</a>
        </div>
        <div className="contact-card">
          <div className="contact-icon">
            <i className="fa-solid fa-book"></i>
          </div>
          <h3>Help Center</h3>
          <p>Access our comprehensive knowledge base with detailed guides and tutorials.</p>
          <a href="#" className="contact-button">Browse Articles</a>
        </div>
      </section>

      {/* Quick Links */}
      <section className="quick-links">
        <h2>Popular Help Topics</h2>
        <div className="links-grid">
          <a href="#" className="quick-link">
            <i className="fa-solid fa-credit-card"></i>
            <span>Billing Issues</span>
          </a>
          <a href="#" className="quick-link">
            <i className="fa-solid fa-wifi"></i>
            <span>Connection Problems</span>
          </a>
          <a href="#" className="quick-link">
            <i className="fa-solid fa-unlock-alt"></i>
            <span>Password Reset</span>
          </a>
          <a href="#" className="quick-link">
            <i className="fa-solid fa-download"></i>
            <span>Download Troubleshooting</span>
          </a>
          <a href="#" className="quick-link">
            <i className="fa-solid fa-cog"></i>
            <span>Account Settings</span>
          </a>
          <a href="#" className="quick-link">
            <i className="fa-solid fa-ban"></i>
            <span>Cancel Subscription</span>
          </a>
        </div>
      </section>

      {/* Chatbot */}
      <Chatbot />
    </div>
  );
};

export default Help; 