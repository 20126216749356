import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [formStatus, setFormStatus] = useState({
    message: '',
    isSuccess: false,
    isError: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Form validation
    if (!formData.name || !formData.email || !formData.message) {
      setFormStatus({
        message: 'Please fill in all required fields',
        isSuccess: false,
        isError: true
      });
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setFormStatus({
        message: 'Please enter a valid email address',
        isSuccess: false,
        isError: true
      });
      return;
    }

    // Simulate sending form data to server
    setTimeout(() => {
      setFormStatus({
        message: 'Thank you for your message! We will get back to you soon.',
        isSuccess: true,
        isError: false
      });
      
      // Reset form data
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      
      // Clear success message after 5 seconds
      setTimeout(() => {
        setFormStatus({
          message: '',
          isSuccess: false,
          isError: false
        });
      }, 5000);
    }, 1000);
  };

  return (
    <div className="contact-container">
      <div className="contact-hero">
        <div className="contact-hero-content">
          <h1>Get In Touch</h1>
          <p>We'd love to hear from you. Our team is always here to help!</p>
        </div>
      </div>

      <div className="contact-content">
        <div className="contact-info">
          <div className="contact-card">
            <div className="contact-icon">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h3>Our Location</h3>
            <p>Fevzi Çakmak, Sakarya Cd. No:156, 35330 Balçova/İzmir</p>
          </div>
          
          <div className="contact-card">
            <div className="contact-icon">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h3>Phone Number</h3>
            <p>+90 (536) 984-1177</p>
            <p>+90 (534) 359-7814</p>
          </div>
          
          <div className="contact-card">
            <div className="contact-icon">
              <i className="fas fa-envelope"></i>
            </div>
            <h3>Email Address</h3>
            <p>support@watchify.com</p>
            <p>info@watchify.com</p>
          </div>
          
          <div className="contact-card">
            <div className="contact-icon">
              <i className="fas fa-clock"></i>
            </div>
            <h3>Working Hours</h3>
            <p>Monday - Friday: 9AM - 5PM</p>
            <p>Weekend: 10AM - 2PM</p>
          </div>
        </div>

        <div className="contact-form-container">
          <h2>Send Us A Message</h2>
          <p>Have a question or feedback? Fill out the form below and we'll respond as soon as possible.</p>
          
          {formStatus.message && (
            <div className={`form-message ${formStatus.isSuccess ? 'success' : ''} ${formStatus.isError ? 'error' : ''}`}>
              {formStatus.isSuccess && <i className="fas fa-check-circle"></i>}
              {formStatus.isError && <i className="fas fa-exclamation-circle"></i>}
              {formStatus.message}
            </div>
          )}
          
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Full Name <span className="required">*</span></label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="email">Email Address <span className="required">*</span></label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="What is this regarding?"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="message">Message <span className="required">*</span></label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Write your message here..."
                rows="5"
              ></textarea>
            </div>
            
            <button type="submit" className="submit-btn">
              Send Message <i className="fas fa-paper-plane"></i>
            </button>
          </form>
        </div>
      </div>


      <section className="contact-faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-container">
          <div className="faq-item">
            <h3>How do I reset my password?</h3>
            <p>You can reset your password by clicking on "Forgot Password" on the login page, or by contacting our support team.</p>
          </div>
          
          <div className="faq-item">
            <h3>Can I cancel my subscription at any time?</h3>
            <p>Yes, you can cancel your subscription anytime from your account settings. Your subscription will remain active until the end of the current billing cycle.</p>
          </div>
          
          <div className="faq-item">
            <h3>How do I report a technical issue?</h3>
            <p>You can report technical issues through the contact form above, or by emailing our support team at support@watchify.com.</p>
          </div>
          
          <div className="faq-item">
            <h3>Is Watchify available on mobile devices?</h3>
            <p>We are working on it. Now, you can only use it on desktop.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact; 