import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./firebase.js";
import {
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import UserPreferences from "./components/UserPreferences.js";
import "./Register.css";
import { collection, query, where, getDocs } from "firebase/firestore";

const Register = () => {
  const [step, setStep] = useState(1); // 1: Personal Info, 2: Preferences
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Form data state
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    nickname: "",
    country: "Turkey",
    city: "",
    gender: "",
    birthDate: "",
    occupation: ""
  });

  const genderOptions = ["Woman", "Man", "Other"];

  const cities = [
    "Adana", "Adıyaman", "Afyonkarahisar", "Ağrı", "Amasya", "Ankara", "Antalya", "Artvin",
    "Aydın", "Balıkesir", "Bilecik", "Bingöl", "Bitlis", "Bolu", "Burdur", "Bursa", "Çanakkale",
    "Çankırı", "Çorum", "Denizli", "Diyarbakır", "Edirne", "Elazığ", "Erzincan", "Erzurum",
    "Eskişehir", "Gaziantep", "Giresun", "Gümüşhane", "Hakkari", "Hatay", "Isparta", "Mersin",
    "İstanbul", "İzmir", "Kars", "Kastamonu", "Kayseri", "Kırklareli", "Kırşehir", "Kocaeli",
    "Konya", "Kütahya", "Malatya", "Manisa", "Kahramanmaraş", "Mardin", "Muğla", "Muş", "Nevşehir",
    "Niğde", "Ordu", "Rize", "Sakarya", "Samsun", "Siirt", "Sinop", "Sivas", "Tekirdağ", "Tokat",
    "Trabzon", "Tunceli", "Şanlıurfa", "Uşak", "Van", "Yozgat", "Zonguldak", "Aksaray", "Bayburt",
    "Karaman", "Kırıkkale", "Batman", "Şırnak", "Bartın", "Ardahan", "Iğdır", "Yalova", "Karabük",
    "Kilis", "Osmaniye", "Düzce"
  ];

  const occupations = [
    "Student", "Engineer", "Doctor", "Teacher", "Lawyer", "Architect", "Developer",
    "Graphic Designer", "Accountant", "Nurse", "Business Manager", "Academic",
    "Psychologist", "Dentist", "Pharmacist", "Police Officer", "Military Personnel", "Chef", "Journalist", "Homemaker",
    "Other"
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validatePersonalInfo = () => {
    if (!formData.firstName || !formData.lastName) {
      setErrorMessage("Name and surname fields are required!");
      return false;
    }
    if (!formData.nickname) {
      setErrorMessage("Nickname is required!");
      return false;
    }
    if (formData.nickname.length < 3) {
      setErrorMessage("Nickname must be at least 3 characters long!");
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return false;
    }
    if (formData.password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long!");
      return false;
    }
    if (!formData.birthDate) {
      setErrorMessage("Birth date is required!");
      return false;
    }
    if (!formData.occupation) {
      setErrorMessage("Occupation selection is required!");
      return false;
    }
    if (!formData.city) {
      setErrorMessage("City selection is required!");
      return false;
    }
    return true;
  };

  const checkNicknameAvailability = async (nickname) => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("nickname", "==", nickname));
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty;
    } catch (error) {
      console.error("Nickname kontrolü sırasında hata:", error);
      return false;
    }
  };

  const handleContinue = async () => {
    if (validatePersonalInfo()) {
      const isNicknameAvailable = await checkNicknameAvailability(formData.nickname);
      if (!isNicknameAvailable) {
        setErrorMessage("This nickname is already taken. Please choose another one.");
        return;
      }
      setStep(2);
      setErrorMessage("");
    }
  };

  const handlePreferencesComplete = async (preferences) => {
    try {
      setLoading(true);
      setErrorMessage("");

      // Create user account
      const userCredential = await createUserWithEmailAndPassword(
        auth, 
        formData.email, 
        formData.password
      );
      const user = userCredential.user;
      
      // Update profile
      await updateProfile(user, { 
        displayName: `${formData.firstName} ${formData.lastName}` 
      });
      
      // Save all user data
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        ...formData,
        createdAt: new Date(),
        preferences: {
          genres: preferences.genres,
          favoriteMovies: preferences.favoriteMovies,
          favoriteShows: preferences.favoriteShows,
          hasDisability: preferences.hasDisability
        }
      });

      navigate("/");
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-page">
      <div className="register-container">
        <div className="register-header">
          <h1 className="brand-title">WATCHIFY</h1>
          <div className="steps-indicator">
            <div className={`step ${step >= 1 ? 'active' : ''}`}>
              <div className="step-number">1</div>
              <div className="step-label">Personal Info</div>
            </div>
            <div className="step-line"></div>
            <div className={`step ${step >= 2 ? 'active' : ''}`}>
              <div className="step-number">2</div>
              <div className="step-label">Preferences</div>
            </div>
          </div>
        </div>

        {errorMessage && <div className="error-message">{errorMessage}</div>}

        {step === 1 ? (
          <div className="register-form">
            <h2>Create Your Account</h2>
            <p className="form-subtitle">Fill in your personal information to get started</p>

            <div className="form-grid">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  className="form-control"
                  placeholder="Enter your first name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  className="form-control"
                  placeholder="Enter your last name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Nickname</label>
                <input
                  type="text"
                  name="nickname"
                  className="form-control"
                  placeholder="Enter your nickname"
                  value={formData.nickname}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Create a password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                  placeholder="Confirm your password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Country</label>
                <select
                  name="country"
                  className="form-control"
                  value={formData.country}
                  onChange={handleInputChange}
                >
                  <option value="Turkey">Turkey</option>
                </select>
              </div>

              <div className="form-group">
                <label>City</label>
                <select
                  name="city"
                  className="form-control"
                  value={formData.city}
                  onChange={handleInputChange}
                >
                  <option value="">Select city</option>
                  {cities.map(city => (
                    <option key={city} value={city}>{city}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Gender</label>
                <select
                  name="gender"
                  className="form-control"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Select gender</option>
                  {genderOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Birth Date</label>
                <input
                  type="date"
                  name="birthDate"
                  className="form-control"
                  value={formData.birthDate}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label>Occupation</label>
                <select
                  name="occupation"
                  className="form-control"
                  value={formData.occupation}
                  onChange={handleInputChange}
                >
                  <option value="">Select occupation</option>
                  {occupations.map(occupation => (
                    <option key={occupation} value={occupation}>{occupation}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-actions">
              <button 
                className="btn-continue" 
                onClick={handleContinue}
                disabled={loading}
              >
                {loading ? "Processing..." : "Continue to Preferences"}
              </button>
              <button 
                className="btn-back" 
                onClick={() => navigate("/login")}
              >
                Back to Login
              </button>
            </div>
          </div>
        ) : (
          <div className="preferences-section">
            <UserPreferences onComplete={handlePreferencesComplete} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Register; 