import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Service.css';

const Service = () => {
  const [activeTab, setActiveTab] = useState('features');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="service-container">
      {/* Hero Section */}
      <section className="service-hero">
        <div className="service-hero-content">
          <h1>AI-Powered Content Discovery</h1>
          <p>Get personalized movie and TV show recommendations based on your preferences and connect with users who share your taste</p>
        </div>
      </section>

      {/* Tab Navigation */}
      <div className="service-tabs">
        <button
          className={`tab-button ${activeTab === 'features' ? 'active' : ''}`}
          onClick={() => handleTabChange('features')}
        >
          <i className="fa-solid fa-lightbulb"></i> Features
        </button>
        <button
          className={`tab-button ${activeTab === 'plans' ? 'active' : ''}`}
          onClick={() => handleTabChange('plans')}
        >
          <i className="fa-solid fa-user-tag"></i> Membership
        </button>
        <button
          className={`tab-button ${activeTab === 'technology' ? 'active' : ''}`}
          onClick={() => handleTabChange('technology')}
        >
          <i className="fa-solid fa-robot"></i> AI Technology
        </button>
      </div>

      {/* Service Content */}
      <div className="service-content">
        {/* Membership Plans Section */}
        {activeTab === 'plans' && (
          <>
            <h2>Choose Your Membership</h2>
            <p className="section-desc">
              Select the membership that works best for you. All plans include unlimited recommendations and basic social features.
            </p>

            <div className="plans-grid">
              <div className="plan-card">
                <div className="plan-header">
                  <h3>Free</h3>
                  <div className="plan-price">
                    <span className="amount">0</span>
                    <span className="period">/forever</span>
                  </div>
                </div>
                <ul className="plan-features">
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Basic recommendations</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Limited filtering options</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Create watchlists</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-times"></i>
                    <span>Advanced AI recommendations</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-times"></i>
                    <span>Ad-free experience</span>
                  </li>
                </ul>
                <button className="plan-button">Sign Up Free</button>
              </div>

              <div className="plan-card standard">
                <div className="plan-tag">Most Popular</div>
                <div className="plan-header">
                  <h3>Premium</h3>
                  <div className="plan-price">
                    <span className="currency">$</span>
                    <span className="amount">4</span>
                    <span className="period">/month</span>
                  </div>
                </div>
                <ul className="plan-features">
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Advanced AI recommendations</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>All filtering options</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Unlimited watchlists</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Ad-free experience</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Priority matching with similar users</span>
                  </li>
                </ul>
                <button className="plan-button">Get Premium</button>
              </div>

              <div className="plan-card">
                <div className="plan-header">
                  <h3>Family</h3>
                  <div className="plan-price">
                    <span className="currency">$</span>
                    <span className="amount">9</span>
                    <span className="period">/month</span>
                  </div>
                </div>
                <ul className="plan-features">
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Everything in Premium</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Up to 5 profiles</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Family content filtering</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Collaborative watchlists</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    <span>Group recommendation engine</span>
                  </li>
                </ul>
                <button className="plan-button">Get Family Plan</button>
              </div>
            </div>

            <div className="plan-info">
              <p>All plans include:</p>
              <ul>
                <li>Recommendation algorithm</li>
                <li>Social features</li>
                <li>Multi-device access</li>
                <li>Community forums</li>
              </ul>
            </div>
          </>
        )}

        {/* Features Section */}
        {activeTab === 'features' && (
          <>
            <h2>Intelligent Features</h2>
            <p className="section-desc">
              Discover how our AI-powered platform enhances your entertainment experience with smart recommendations and social connections.
            </p>

            <div className="features-grid">
              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-brain"></i>
                </div>
                <h3>Smart Recommendations</h3>
                <p>Our AI analyzes your viewing history, ratings, and preferences to suggest content you'll love, with increasing accuracy over time.</p>
              </div>

              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-filter"></i>
                </div>
                <h3>Advanced Filtering</h3>
                <p>Filter content by genre, cast, director, release year, rating, and more to find exactly what you're looking for.</p>
              </div>

              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-users"></i>
                </div>
                <h3>Social Matching</h3>
                <p>Connect with users who share your taste in movies and TV shows, expanding your social circle and content discovery.</p>
              </div>

              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-comments"></i>
                </div>
                <h3>Chat & Share</h3>
                <p>Discuss content with friends, share recommendations, and collaborate on watchlists to enhance your entertainment experience.</p>
              </div>

              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-list-check"></i>
                </div>
                <h3>Custom Watchlists</h3>
                <p>Create, organize, and share watchlists based on genres, moods, or any criteria you choose.</p>
              </div>

              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-message"></i>
                </div>
                <h3>AI Chatbot Assistant</h3>
                <p>Get instant recommendations and answers to your entertainment questions through our advanced NLP-powered chatbot.</p>
              </div>
            </div>
          </>
        )}

        {/* AI Technology Section */}
        {activeTab === 'technology' && (
          <>
            <h2>Our AI Technology</h2>
            <p className="section-desc">
              Learn about the innovative AI technologies that power our recommendation engine and enhance your content discovery.
            </p>

            <div className="features-grid">
              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-code-branch"></i>
                </div>
                <h3>Collaborative Filtering</h3>
                <p>Our system identifies patterns among users with similar preferences to suggest content you're likely to enjoy based on what others with similar tastes have liked.</p>
              </div>

              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-tags"></i>
                </div>
                <h3>Content-Based Analysis</h3>
                <p>We analyze the attributes of content you've enjoyed—such as genre, actors, directors, and themes—to recommend similar items.</p>
              </div>

              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-comments"></i>
                </div>
                <h3>Natural Language Processing</h3>
                <p>Our chatbot uses advanced NLP to understand your queries, preferences, and feedback to provide personalized recommendations.</p>
              </div>

              <div className="feature-card">
                <div className="feature-icon">
                  <i className="fa-solid fa-chart-line"></i>
                </div>
                <h3>Machine Learning</h3>
                <p>Our recommendation engine continuously learns from user interactions to improve accuracy and relevance over time.</p>
              </div>
            </div>

            <div className="devices-note">
              <i className="fa-solid fa-circle-info"></i> Our AI technology respects your privacy. We use your data solely to improve recommendations.
            </div>
          </>
        )}
      </div>

      {/* Testimonials Section */}
      <section className="service-testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonials-container">
          <div className="testimonial-card">
            <div className="testimonial-quote">
              <i className="fa-solid fa-quote-left"></i>
            </div>
            <p>Watchify completely transformed how I discover new movies. The recommendations are spot-on, and I've connected with people who share my taste in foreign films!</p>
            <div className="testimonial-author">
              <div className="author-avatar">
                <img src="https://via.placeholder.com/50x50" alt="User avatar" />
              </div>
              <div className="author-info">
                <h4>Sarah J.</h4>
                <span>Film Enthusiast</span>
              </div>
            </div>
          </div>

          <div className="testimonial-card">
            <div className="testimonial-quote">
              <i className="fa-solid fa-quote-left"></i>
            </div>
            <p>The AI chatbot understands exactly what I'm looking for. I was skeptical at first, but now I can't imagine discovering content without Watchify's recommendations.</p>
            <div className="testimonial-author">
              <div className="author-avatar">
                <img src="https://via.placeholder.com/50x50" alt="User avatar" />
              </div>
              <div className="author-info">
                <h4>Michael T.</h4>
                <span>Tech Blogger</span>
              </div>
            </div>
          </div>

          <div className="testimonial-card">
            <div className="testimonial-quote">
              <i className="fa-solid fa-quote-left"></i>
            </div>
            <p>My family has diverse tastes, but Watchify helps us find content we all enjoy. The collaborative watchlists feature is a game-changer for our movie nights!</p>
            <div className="testimonial-author">
              <div className="author-avatar">
                <img src="https://via.placeholder.com/50x50" alt="User avatar" />
              </div>
              <div className="author-info">
                <h4>David & Lisa</h4>
                <span>Family Plan Users</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}

    </div>
  );
};

export default Service; 