import React, { useState, useEffect, useRef } from "react";
import { db, collection, addDoc, getDocs, orderBy, query } from "../firebase.js";
import "./Chatbot.css";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    // Başlangıç mesajlarını ayarla
    const initialMessages = [
      {
        text: "Merhaba! Ben Watchify'ın AI asistanıyım. Size yardımcı olmaktan mutluluk duyarım.",
        sender: "bot"
      },
      {
        text: "Watchify, film ve dizi önerileri sunan akıllı bir platformdur. Size özel öneriler sunmak için tercihlerinizi öğrenmek isteriz.",
        sender: "bot"
      },
      {
        text: "Kayıt olmak için:",
        sender: "bot"
      },
      {
        text: "1. E-posta adresinizi girin\n2. Güçlü bir şifre belirleyin\n3. Kişisel bilgilerinizi doldurun\n4. Favori film ve dizi türlerinizi seçin",
        sender: "bot"
      },
      {
        text: "Giriş yapmak için kayıtlı e-posta adresiniz ve şifrenizi kullanabilirsiniz.",
        sender: "bot"
      },
      {
        text: "Herhangi bir sorunuz olursa, size yardımcı olmaktan mutluluk duyarım!",
        sender: "bot"
      }
    ];
    setMessages(initialMessages);
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`chatbot-container ${isOpen ? 'open' : 'closed'}`}>
      {isOpen ? (
        <>
          <div className="chatbot-header">
            <div className="chatbot-title">
              <div className="chatbot-avatar">
                <i className="fas fa-robot"></i>
              </div>
              <span>Watchify AI Asistanı</span>
            </div>
            <div className="chatbot-actions">
              <button className="minimize-btn" onClick={toggleChat}>
                <i className="fas fa-minus"></i>
              </button>
            </div>
          </div>
          
          <div className="chatbot-box" ref={chatBoxRef}>
            {messages.map((msg, index) => (
              <div key={index} className={`chatbot-message ${msg.sender}`}>
                {msg.sender === 'bot' && (
                  <div className="message-avatar">
                    <i className="fas fa-robot"></i>
                  </div>
                )}
                <div className="message-content">{msg.text}</div>
              </div>
            ))}
          </div>
          
          <div className="chatbot-footer">
            <p>Watchify AI Asistanı</p>
          </div>
        </>
      ) : (
        <button className="chatbot-button" onClick={toggleChat}>
          <i className="fas fa-comments"></i>
        </button>
      )}
    </div>
  );
};

export default Chatbot;
