import React, { useState, useEffect } from 'react';
import './UserPreferences.css';

const UserPreferences = ({ onComplete, userId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedMovies, setSelectedMovies] = useState([]);
  const [selectedShows, setSelectedShows] = useState([]);
  const [hasDisability, setHasDisability] = useState(false);
  const [popularMovies, setPopularMovies] = useState([]);
  const [popularShows, setPopularShows] = useState([]);

  const genres = [
    { id: 28, name: "Action" },
    { id: 12, name: "Adventure" },
    { id: 16, name: "Animation" },
    { id: 35, name: "Comedy" },
    { id: 80, name: "Crime" },
    { id: 99, name: "Documentary" },
    { id: 18, name: "Drama" },
    { id: 10751, name: "Family" },
    { id: 14, name: "Fantasy" },
    { id: 36, name: "History" },
    { id: 27, name: "Horror" },
    { id: 10402, name: "Music" },
    { id: 9648, name: "Mystery" },
    { id: 10749, name: "Romance" },
    { id: 878, name: "Science Fiction" },
    { id: 10770, name: "TV Movie" },
    { id: 53, name: "Thriller" },
    { id: 10752, name: "War" },
    { id: 37, name: "Western" }
  ];

  useEffect(() => {
    const fetchPopularContent = async () => {
      try {
        const TMDB_API_KEY = '6dbd0883634bc7635521b42a82600be0';
        console.log('Fetching popular content...');
        
        const moviesResponse = await fetch(
          `https://api.themoviedb.org/3/movie/popular?api_key=${TMDB_API_KEY}&language=en-US&page=1`
        );
        
        if (!moviesResponse.ok) {
          throw new Error(`Movies API error: ${moviesResponse.status}`);
        }
        
        const moviesData = await moviesResponse.json();
        console.log('Movies data received:', moviesData);
        setPopularMovies(moviesData.results.slice(0, 20));

        const showsResponse = await fetch(
          `https://api.themoviedb.org/3/tv/popular?api_key=${TMDB_API_KEY}&language=en-US&page=1`
        );
        
        if (!showsResponse.ok) {
          throw new Error(`Shows API error: ${showsResponse.status}`);
        }
        
        const showsData = await showsResponse.json();
        console.log('Shows data received:', showsData);
        setPopularShows(showsData.results.slice(0, 20));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching popular content:', error);
        setError('Error loading content. Please try again.');
        setLoading(false);
      }
    };

    fetchPopularContent();
  }, []);

  const handleGenreSelect = (genreId) => {
    setSelectedGenres(prev => {
      if (prev.includes(genreId)) {
        return prev.filter(id => id !== genreId);
      }
      if (prev.length < 4) {
        return [...prev, genreId];
      }
      return prev;
    });
  };

  const handleMovieSelect = (movieId) => {
    setSelectedMovies(prev => {
      if (prev.includes(movieId)) {
        return prev.filter(id => id !== movieId);
      }
      if (prev.length < 5) {
        return [...prev, movieId];
      }
      return prev;
    });
  };

  const handleShowSelect = (showId) => {
    setSelectedShows(prev => {
      if (prev.includes(showId)) {
        return prev.filter(id => id !== showId);
      }
      if (prev.length < 5) {
        return [...prev, showId];
      }
      return prev;
    });
  };

  const handleSubmit = async () => {
    try {
      const preferences = {
        genres: selectedGenres,
        favoriteMovies: selectedMovies,
        favoriteShows: selectedShows,
        hasDisability
      };

      await onComplete(preferences);
    } catch (error) {
      setError('Tercihler kaydedilirken bir hata oluştu.');
    }
  };

  if (loading) {
    return <div className="preferences-loading">Loading...</div>;
  }

  if (error) {
    return <div className="preferences-error">{error}</div>;
  }

  return (
    <div className="preferences-container">
      <div className="preferences-progress">
        <div className={`progress-step ${step >= 1 ? 'active' : ''}`}>1</div>
        <div className={`progress-step ${step >= 2 ? 'active' : ''}`}>2</div>
        <div className={`progress-step ${step >= 3 ? 'active' : ''}`}>3</div>
      </div>

      {step === 1 && (
        <div className="preferences-step">
          <h2>Movie and TV Show Genres</h2>
          <p>Select your top 4 favorite genres:</p>
          <div className="genres-grid">
            {genres.map(genre => (
              <button
                key={genre.id}
                className={`genre-button ${selectedGenres.includes(genre.id) ? 'selected' : ''}`}
                onClick={() => handleGenreSelect(genre.id)}
              >
                {genre.name}
              </button>
            ))}
          </div>
          <button
            className="next-button"
            onClick={() => setStep(2)}
            disabled={selectedGenres.length === 0}
          >
            Next
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="preferences-step">
          <h2>Favorite Movies</h2>
          <p>Select your top 5 favorite movies:</p>
          <div className="content-grid">
            {popularMovies.map(movie => (
              <div
                key={movie.id}
                className={`content-card ${selectedMovies.includes(movie.id) ? 'selected' : ''}`}
                onClick={() => handleMovieSelect(movie.id)}
              >
                <img
                  src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`}
                  alt={movie.title}
                />
                <h3>{movie.title}</h3>
              </div>
            ))}
          </div>
          <div className="step-buttons">
            <button className="back-button" onClick={() => setStep(1)}>
              Back
            </button>
            <button
              className="next-button"
              onClick={() => setStep(3)}
              disabled={selectedMovies.length === 0}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="preferences-step">
          <h2>Favorite TV Shows</h2>
          <p>Select your top 5 favorite TV shows:</p>
          <div className="content-grid">
            {popularShows.map(show => (
              <div
                key={show.id}
                className={`content-card ${selectedShows.includes(show.id) ? 'selected' : ''}`}
                onClick={() => handleShowSelect(show.id)}
              >
                <img
                  src={`https://image.tmdb.org/t/p/w200${show.poster_path}`}
                  alt={show.name}
                />
                <h3>{show.name}</h3>
              </div>
            ))}
          </div>
          <div className="step-buttons">
            <button className="back-button" onClick={() => setStep(2)}>
              Back
            </button>
            <button
              className="next-button"
              onClick={() => setStep(4)}
              disabled={selectedShows.length === 0}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {step === 4 && (
        <div className="preferences-step">
          <h2>Accessibility</h2>
          <p>Do you have any accessibility needs?</p>
          <div className="accessibility-options">
            <label className="accessibility-option">
              <input
                type="checkbox"
                checked={hasDisability}
                onChange={(e) => setHasDisability(e.target.checked)}
              />
              Yes, I need accessibility support
            </label>
          </div>
          <div className="step-buttons">
            <button className="back-button" onClick={() => setStep(3)}>
              Back
            </button>
            <button className="submit-button" onClick={handleSubmit}>
              Complete Registration
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPreferences; 