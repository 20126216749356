import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase.js';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import './Recommendations.css';

const Recommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [userPreferences, setUserPreferences] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [userFeedback, setUserFeedback] = useState({});

  // TMDB API base URL and parameters
  const TMDB_API_KEY = '6dbd0883634bc7635521b42a82600be0';
  const TMDB_BASE_URL = 'https://api.themoviedb.org/3';

  // Get user preferences
  const fetchUserPreferences = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not found');
      }

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User preferences:', userData);
        
        // Get preferences from preferences object
        const preferences = userData.preferences || {};
        
        setUserPreferences({
          age: calculateAge(userData.birthDate),
          gender: userData.gender,
          occupation: userData.occupation,
          location: userData.city,
          favoriteGenres: preferences.genres || [],
          favoriteMovies: preferences.favoriteMovies || [],
          favoriteShows: preferences.favoriteShows || []
        });
      } else {
        console.error('User document not found');
        setError('User information not found');
      }
    } catch (error) {
      console.error('Error fetching user preferences:', error);
      setError('User preferences could not be loaded');
    }
  };

  // Age calculation function
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  // Get similar content
  const fetchSimilarContent = async (contentId, contentType) => {
    try {
      const response = await axios.get(
        `${TMDB_BASE_URL}/${contentType}/${contentId}/similar`,
        {
          params: {
            api_key: TMDB_API_KEY,
            language: 'en-US',
            page: 1
          }
        }
      );
      return response.data.results.slice(0, 2);
    } catch (error) {
      console.error(`Error fetching similar content (${contentType}/${contentId}):`, error);
      return [];
    }
  };

  // Get recommendations
  const fetchRecommendations = async () => {
    try {
      if (!userPreferences) {
        console.log('User preferences not loaded yet');
        return;
      }

      console.log('Fetching recommendations...', userPreferences);
      let allRecommendations = [];

      // Get recommendations based on favorite genres
      if (userPreferences.favoriteGenres && userPreferences.favoriteGenres.length > 0) {
        console.log('Favorite genres:', userPreferences.favoriteGenres);
        for (const genre of userPreferences.favoriteGenres) {
          // Movie recommendations
          const movieResponse = await axios.get(`${TMDB_BASE_URL}/discover/movie`, {
            params: {
              api_key: TMDB_API_KEY,
              with_genres: genre,
              language: 'en-US',
              sort_by: 'popularity.desc',
              page: 1
            }
          });

          // TV show recommendations
          const tvResponse = await axios.get(`${TMDB_BASE_URL}/discover/tv`, {
            params: {
              api_key: TMDB_API_KEY,
              with_genres: genre,
              language: 'en-US',
              sort_by: 'popularity.desc',
              page: 1
            }
          });

          allRecommendations.push(...movieResponse.data.results.slice(0, 2));
          allRecommendations.push(...tvResponse.data.results.slice(0, 2));
        }
      }

      // Get similar content based on favorite movies and shows
      if (userPreferences.favoriteMovies && userPreferences.favoriteMovies.length > 0) {
        console.log('Favorite Movies:', userPreferences.favoriteMovies);
        for (const movieId of userPreferences.favoriteMovies) {
          const similarMovies = await fetchSimilarContent(movieId, 'movie');
          allRecommendations.push(...similarMovies);
        }
      }

      if (userPreferences.favoriteShows && userPreferences.favoriteShows.length > 0) {
        console.log('Favorite Shows:', userPreferences.favoriteShows);
        for (const showId of userPreferences.favoriteShows) {
          const similarShows = await fetchSimilarContent(showId, 'tv');
          allRecommendations.push(...similarShows);
        }
      }

      console.log('Total recommendations:', allRecommendations.length);

      // Remove duplicate content
      const uniqueRecommendations = Array.from(new Set(allRecommendations.map(item => item.id)))
        .map(id => allRecommendations.find(item => item.id === id));

      console.log('After removing duplicates:', uniqueRecommendations.length);

      // Shuffle and get top 10 recommendations
      const shuffled = uniqueRecommendations.sort(() => 0.5 - Math.random());
      const finalRecommendations = shuffled.slice(0, 10);
      console.log('Final recommendations:', finalRecommendations);
      
      setRecommendations(finalRecommendations);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setError('Recommendations could not be loaded');
      setLoading(false);
    }
  };

  // User feedback
  const handleFeedback = async (itemId, isLiked) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      // Save feedback to state
      setUserFeedback(prev => ({
        ...prev,
        [itemId]: isLiked
      }));

      // Save feedback to Firestore
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, {
        [`recommendations.${itemId}`]: isLiked
      });
    } catch (error) {
      console.error('Error saving feedback:', error);
    }
  };

  useEffect(() => {
    fetchUserPreferences();
  }, []);

  useEffect(() => {
    if (userPreferences) {
      fetchRecommendations();
    }
  }, [userPreferences]);

  if (loading) {
    return <div className="loading">Loading recommendations...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="recommendations-container">
      <h2>Personalized Recommendations</h2>
      <div className="recommendations-grid">
        {recommendations.map((item) => (
          <div key={item.id} className="recommendation-card">
            <img 
              src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} 
              alt={item.title || item.name}
              onError={(e) => {
                e.target.src = 'https://via.placeholder.com/500x750?text=Image+Not+Found';
              }}
            />
            <div className="recommendation-info">
              <h3>{item.title || item.name}</h3>
              <p>{item.overview}</p>
              <div className="feedback-buttons">
                <button
                  className={`like-button ${userFeedback[item.id] === true ? 'active' : ''}`}
                  onClick={() => handleFeedback(item.id, true)}
                >
                  👍 Like
                </button>
                <button
                  className={`dislike-button ${userFeedback[item.id] === false ? 'active' : ''}`}
                  onClick={() => handleFeedback(item.id, false)}
                >
                  👎 Dislike
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recommendations; 