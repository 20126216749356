import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../firebase.js';
import { doc, getDoc } from 'firebase/firestore';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [socialLinks, setSocialLinks] = useState({
    instagram: '',
    twitter: ''
  });

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setSocialLinks({
              instagram: data.instagram || '',
              twitter: data.twitter || ''
            });
          }
        }
      } catch (error) {
        console.error('Error fetching social links:', error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <footer className="site-footer">
      <div className="footer-container">
        <div className="footer-top">
          <div className="footer-logo">
            
            <h2 className="footer-logo-text">Watchify</h2>
            <p className="footer-slogan">Stream everything, anytime, anywhere.</p>
            
            <div className="social-links">
              
              <a 
                href={`https://x.com/thewatchify${socialLinks.twitter}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="social-link"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a 
                href={`https://www.instagram.com/thewatchify_com?igsh=bmhoa3B6YzllMjh4${socialLinks.instagram}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="social-link"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="social-link">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          
          <div className="footer-links">
            <div className="footer-links-column">
              <h3>Navigation</h3>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/service">Services</Link></li>
                <li><Link to="/movies">Movies</Link></li>
                <li><Link to="/shows">TV Shows</Link></li>
                <li><Link to="/new">New & Popular</Link></li>
              </ul>
            </div>
            
            <div className="footer-links-column">
              <h3>Support</h3>
              <ul>
                <li><Link to="/help">Help Center</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/contact">FAQ</Link></li>
              </ul>
            </div>
            
           
            
            <div className="footer-links-column">
              <h3>Account</h3>
              <ul>
                <li><Link to="/profile">My Profile</Link></li>
                <li><Link to="/account">Account Settings</Link></li>
                <li><Link to="/billing">Billing Details</Link></li>
                <li><Link to="/watchlist">My Watchlist</Link></li>
                
              </ul>
            </div>
          </div>
        </div>
        
      
        
        <div className="footer-bottom">
          <div className="copyright">
            <p>© {currentYear} Watchify. All rights reserved.</p>
            <p>Watchify is a streaming service offering movies and TV series on internet-connected devices.</p>
          </div>
          
          <div className="language-selector">
            <div className="language-button">
              <i className="fa-solid fa-globe"></i>
              <span>English</span>
              <i className="fa-solid fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 