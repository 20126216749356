// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, orderBy, getDocs, addDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAzZygCyYaiqRvAcfix6Zll0h-_SjyBlTY",
  authDomain: "watchify-197e9.firebaseapp.com",
  projectId: "watchify-197e9",
  storageBucket: "watchify-197e9.firebasestorage.app",
  messagingSenderId: "1025364294073",
  appId: "1:1025364294073:web:8710098ec38f645f56de68",
  measurementId: "G-11EGM9FFG8"
};

// Firebase başlat
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export { collection, query, orderBy, getDocs, addDoc };
export default app;
