import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase.js';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import './Profile.css';

const Profile = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    nickname: '',
    city: '',
    country: 'Turkey',
    birthDate: '',
    gender: '',
    occupation: ''
  });
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);

  const turkishCities = [
    "Adana", "Adıyaman", "Afyonkarahisar", "Ağrı", "Amasya", "Ankara", "Antalya", "Artvin", "Aydın", "Balıkesir",
    "Bilecik", "Bingöl", "Bitlis", "Bolu", "Burdur", "Bursa", "Çanakkale", "Çankırı", "Çorum", "Denizli",
    "Diyarbakır", "Edirne", "Elazığ", "Erzincan", "Erzurum", "Eskişehir", "Gaziantep", "Giresun", "Gümüşhane", "Hakkari",
    "Hatay", "Isparta", "Mersin", "İstanbul", "İzmir", "Kars", "Kastamonu", "Kayseri", "Kırklareli", "Kırşehir",
    "Kocaeli", "Konya", "Kütahya", "Malatya", "Manisa", "Kahramanmaraş", "Mardin", "Muğla", "Muş", "Nevşehir",
    "Niğde", "Ordu", "Rize", "Sakarya", "Samsun", "Siirt", "Sinop", "Sivas", "Tekirdağ", "Tokat",
    "Trabzon", "Tunceli", "Şanlıurfa", "Uşak", "Van", "Yozgat", "Zonguldak", "Aksaray", "Bayburt", "Karaman",
    "Kırıkkale", "Batman", "Şırnak", "Bartın", "Ardahan", "Iğdır", "Yalova", "Karabük", "Kilis", "Osmaniye", "Düzce"
  ];

  const occupations = [
    "Student", "Engineer", "Doctor", "Teacher", "Lawyer", "Architect", "Developer",
    "Graphic Designer", "Accountant", "Nurse", "Business Manager", "Academic",
    "Psychologist", "Dentist", "Pharmacist", "Police Officer", "Military Personnel", "Chef", "Journalist", "Homemaker",
    "Other"
  ];

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        setError('User session not found');
        return;
      }

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const data = userDoc.data();
        setFormData({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: user.email || '',
          nickname: data.nickname || '',
          city: data.city || '',
          country: data.country || 'Turkey',
          birthDate: data.birthDate || '',
          gender: data.gender || '',
          occupation: data.occupation || ''
        });
      }
    } catch (error) {
      console.error('Error loading profile:', error);
      setError('An error occurred while loading profile');
    } finally {
      setLoading(false);
    }
  };

  const checkNicknameAvailability = async (nickname) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('nickname', '==', nickname));
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty;
    } catch (error) {
      console.error('Nickname kontrolü sırasında hata:', error);
      throw error;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setEditing(true);
    setError('');
    setSuccess('');
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setEditing(false);
    fetchProfileData();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!editing) return;
    
    setSaving(true);
    setError('');
    setSuccess('');

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User session not found');
      }

      const isNicknameAvailable = await checkNicknameAvailability(formData.nickname);
      if (!isNicknameAvailable) {
        setError('This nickname is already taken. Please choose another one.');
        setSaving(false);
        return;
      }

      await updateDoc(doc(db, 'users', user.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        nickname: formData.nickname,
        city: formData.city,
        country: formData.country,
        birthDate: formData.birthDate,
        gender: formData.gender,
        occupation: formData.occupation
      });

      await updateProfile(user, {
        displayName: `${formData.firstName} ${formData.lastName}`
      });

      setSuccess('Profile updated successfully');
      setEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('An error occurred while updating profile');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading profile...</div>;
  }

  return (
    <div className="profile-container">
      <div className="profile-card">
        <h2>Profile Information</h2>
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              disabled={!editing}
              required
            />
          </div>

          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              disabled={!editing}
              required
            />
          </div>

          <div className="form-group">
            <label>Nickname</label>
            <input
              type="text"
              name="nickname"
              value={formData.nickname}
              onChange={handleInputChange}
              disabled={!editing}
              required
              minLength={3}
            />
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              disabled
            />
          </div>

          <div className="form-group">
            <label>City</label>
            <select
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              disabled={!editing}
              required
            >
              <option value="">Select a city</option>
              {turkishCities.map(city => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Country</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              disabled
            />
          </div>

          <div className="form-group">
            <label>Birth Date</label>
            <input
              type="date"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleInputChange}
              disabled={!editing}
              required
            />
          </div>

          <div className="form-group">
            <label>Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              disabled={!editing}
              required
            >
              <option value="">Select gender</option>
              <option value="Woman">Woman</option>
              <option value="Man">Man</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="form-group">
            <label>Occupation</label>
            <select
              name="occupation"
              value={formData.occupation}
              onChange={handleInputChange}
              disabled={!editing}
              required
            >
              <option value="">Select occupation</option>
              {occupations.map(occupation => (
                <option key={occupation} value={occupation}>{occupation}</option>
              ))}
            </select>
          </div>

          <div className="button-group">
            {!editing ? (
              <button type="button" onClick={handleEdit} className="edit-button">
                Edit Profile
              </button>
            ) : (
              <>
                <button type="submit" className="save-button" disabled={saving}>
                  {saving ? 'Saving...' : 'Save Changes'}
                </button>
                <button type="button" onClick={handleCancel} className="cancel-button">
                  Cancel
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
