import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Chatbot from './Chatbot.js';
import './Home.css';

const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const TMDB_BASE_URL = 'https://api.themoviedb.org/3';
const TMDB_IMAGE_BASE_URL = 'https://image.tmdb.org/t/p/w500';

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isVisible, setIsVisible] = useState({});
  const [topMovies, setTopMovies] = useState([]);
  const [topShows, setTopShows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [heroSlides] = useState([
    {
      id: 1,
      title: "Stranger Things Season 5",
      description: "The epic conclusion to the supernatural saga. Get personalized recommendations for similar content.",
      buttonText: "Find Similar",
      image: "https://image.tmdb.org/t/p/original/49WJfeN0moxb9IPfGn8AIqMGskD.jpg",
      color: "linear-gradient(90deg, rgba(26,9,9,1) 0%, rgba(142,33,33,1) 100%)"
    },
    {
      id: 2,
      title: "Friends",
      description: "Six young people, on their own and struggling to survive in the real world, find the companionship, comfort and support they get from each other to be the perfect antidote to the pressures of life.",
      buttonText: "Explore Recommendations",
      image: "https://image.tmdb.org/t/p/original/f496cm9enuEsZkSPzCwnTESEK5s.jpg",
      color: "linear-gradient(90deg, rgba(17,24,39,1) 0%, rgba(55,65,81,1) 100%)"
    },
    {
      id: 3,
      title: "House of the Dragon",
      description: "The Targaryen dynasty is at the height of its power. Discover content that matches your preferences.",
      buttonText: "Get Recommendations",
      image: "https://image.tmdb.org/t/p/original/z2yahl2uefxDCl0nogcRBstwruJ.jpg",
      color: "linear-gradient(90deg, rgba(20,30,48,1) 0%, rgba(36,59,85,1) 100%)"
    }
  ]);

  const features = [
    {
      id: 1,
      icon: "fa-solid fa-lightbulb",
      title: "Personalized Recommendations",
      description: "Get movie and TV show recommendations tailored to your unique preferences and taste."
    },
    {
      id: 2,
      icon: "fa-solid fa-users",
      title: "Connect With Similar Tastes",
      description: "Match with users who share your movie preferences and build your entertainment network."
    },
    {
      id: 3,
      icon: "fa-solid fa-user-group",
      title: "Social Watchlists",
      description: "Create and share watchlists with friends and discover new content together."
    },
    {
      id: 4,
      icon: "fa-solid fa-globe",
      title: "Global Content Discovery",
      description: "Explore international content with recommendations from our extensive library of foreign films and shows."
    }
  ];

  useEffect(() => {
    if (!TMDB_API_KEY) {
      setError('TMDB API anahtarı bulunamadı. Lütfen .env dosyasını kontrol edin.');
      setLoading(false);
      return;
    }

    fetchTopMovies();
    fetchTopShows();
  }, []);

  const fetchTopMovies = async () => {
    try {
      const response = await fetch(
        `${TMDB_BASE_URL}/movie/top_rated?api_key=${TMDB_API_KEY}&language=tr-TR`
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.status_message || 'Filmler yüklenirken bir hata oluştu');
      }

      const data = await response.json();
      
      if (!data.results || !Array.isArray(data.results)) {
        throw new Error('Geçersiz API yanıtı');
      }

      const movies = data.results.slice(0, 6).map(movie => ({
        id: movie.id,
        title: movie.title,
        genre: movie.genre_ids.join(', '),
        rating: movie.vote_average.toFixed(1),
        image: movie.poster_path ? `${TMDB_IMAGE_BASE_URL}${movie.poster_path}` : 'https://via.placeholder.com/300x450?text=No+Image'
      }));
      
      setTopMovies(movies);
    } catch (error) {
      console.error('Filmler yüklenirken hata oluştu:', error);
      setError(`Filmler yüklenirken bir hata oluştu: ${error.message}`);
    }
  };

  const fetchTopShows = async () => {
    try {
      const response = await fetch(
        `${TMDB_BASE_URL}/tv/top_rated?api_key=${TMDB_API_KEY}&language=tr-TR`
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.status_message || 'Diziler yüklenirken bir hata oluştu');
      }

      const data = await response.json();
      
      if (!data.results || !Array.isArray(data.results)) {
        throw new Error('Geçersiz API yanıtı');
      }

      const shows = data.results.slice(0, 6).map(show => ({
        id: show.id,
        title: show.name,
        genre: show.genre_ids.join(', '),
        rating: show.vote_average.toFixed(1),
        image: show.poster_path ? `${TMDB_IMAGE_BASE_URL}${show.poster_path}` : 'https://via.placeholder.com/300x450?text=No+Image'
      }));
      
      setTopShows(shows);
    } catch (error) {
      console.error('Diziler yüklenirken hata oluştu:', error);
      setError(`Diziler yüklenirken bir hata oluştu: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % heroSlides.length);
    }, 6000);

    return () => {
      clearInterval(timer);
    };
  }, [heroSlides.length]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(prev => ({ ...prev, [entry.target.id]: true }));
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    
    document.querySelectorAll('.animate-section').forEach(section => {
      observer.observe(section);
    });

    return () => {
      document.querySelectorAll('.animate-section').forEach(section => {
        observer.unobserve(section);
      });
    };
  }, []);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + heroSlides.length) % heroSlides.length);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % heroSlides.length);
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const handleRecommendationsClick = () => {
    navigate('/recommendations');
  };

  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-slider">
          {heroSlides.map((slide, index) => (
            <div
              key={slide.id}
              className={`hero-slide ${index === currentSlide ? 'active' : ''}`}
              style={{
                backgroundImage: `url(${slide.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="hero-overlay" style={{ background: slide.color }}></div>
              <div className="hero-content">
                <h1>{slide.title}</h1>
                <p>{slide.description}</p>
                <button className="hero-button" onClick={() => navigate('/recommendations')}>
                  {slide.buttonText}
                </button>
              </div>
            </div>
          ))}
          
          <div className="slider-controls">
            <button className="prev-slide" onClick={handlePrevSlide}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <div className="slider-dots">
              {heroSlides.map((_, index) => (
                <span 
                  key={index} 
                  className={`slider-dot ${index === currentSlide ? 'active' : ''}`}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>
            <button className="next-slide" onClick={handleNextSlide}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className={`features-section animate-section ${isVisible['features'] ? 'visible' : ''}`}>
        <div className="section-header">
          <h2>Experience Watchify</h2>
          <p>Everything you need for smarter entertainment discovery</p>
        </div>
        <div className="features-grid">
          {features.map(feature => (
            <div key={feature.id} className="feature-card">
              <div className="feature-icon">
                <i className={feature.icon}></i>
              </div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Movies Section */}
      <section id="top-movies" className={`content-section animate-section ${isVisible['top-movies'] ? 'visible' : ''}`}>
        <div className="section-header">
          <h2>Top Movies</h2>
          <p>Our most recommended movies this week</p>
        </div>
        {error && <div className="error-message">{error}</div>}
        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          <div className="content-slider">
            {topMovies.map(movie => (
              <div key={movie.id} className="content-card">
                <div className="content-image">
                  <img 
                    src={movie.image} 
                    alt={movie.title}
                    onError={(e) => {
                      console.error('Resim yüklenemedi:', movie.image);
                      e.target.src = 'https://via.placeholder.com/300x450?text=No+Image';
                    }}
                  />
                  <div className="content-overlay">
                    <button className="play-button">
                      <i className="fa-solid fa-info"></i>
                    </button>
                    <div className="content-actions">
                      <button className="action-button">
                        <i className="fa-solid fa-plus"></i>
                      </button>
                      <button className="action-button">
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="content-info">
                  <h3>{movie.title}</h3>
                  <div className="content-details">
                    <span className="content-genre">{movie.genre}</span>
                    <span className="content-rating">
                      <i className="fa-solid fa-star"></i> {movie.rating}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="see-more">
          <Link to="/movies">See All Movies <i className="fa-solid fa-arrow-right"></i></Link>
        </div>
      </section>

      {/* Shows Section */}
      <section id="top-shows" className={`content-section animate-section ${isVisible['top-shows'] ? 'visible' : ''}`}>
        <div className="section-header">
          <h2>Top TV Shows</h2>
          <p>Most recommended series based on user preferences</p>
        </div>
        {error && <div className="error-message">{error}</div>}
        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          <div className="content-slider">
            {topShows.map(show => (
              <div key={show.id} className="content-card">
                <div className="content-image">
                  <img 
                    src={show.image} 
                    alt={show.title}
                    onError={(e) => {
                      console.error('Resim yüklenemedi:', show.image);
                      e.target.src = 'https://via.placeholder.com/300x450?text=No+Image';
                    }}
                  />
                  <div className="content-overlay">
                    <button className="play-button">
                      <i className="fa-solid fa-info"></i>
                    </button>
                    <div className="content-actions">
                      <button className="action-button">
                        <i className="fa-solid fa-plus"></i>
                      </button>
                      <button className="action-button">
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="content-info">
                  <h3>{show.title}</h3>
                  <div className="content-details">
                    <span className="content-genre">{show.genre}</span>
                    <span className="content-rating">
                      <i className="fa-solid fa-star"></i> {show.rating}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="see-more">
          <Link to="/shows">See All Shows <i className="fa-solid fa-arrow-right"></i></Link>
        </div>
      </section>

    
      
    </div>
  );
};

export default Home; 