import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { auth } from "./firebase.js";
import { onAuthStateChanged } from 'firebase/auth';

// Import our components
import Login from "./Login.js";
import Register from "./Register.js";
import Home from "./components/Home.js";
import Service from "./components/Service.js";
import Contact from "./components/Contact.js";
import Help from "./components/Help.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Profile from "./Profile.js";
import Recommendations from './components/Recommendations.js';
import Chatbot from './components/Chatbot.js';
import Friends from './components/Friends.js';

// Import styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <h2>Loading Watchify...</h2>
      </div>
    );
  }

  return (
    <Router>
      <div className="app">
        {user ? (
          // Authenticated layout
          <>
            <Header />
            <main className="main-content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/service" element={<Service />} />
                <Route path="/help" element={<Help />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/recommendations" element={<Recommendations />} />
                <Route path="/friends" element={<Friends />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </main>
            <Footer />
            <Chatbot />
          </>
        ) : (
          // Unauthenticated layout - only Login and Register are accessible
          <div className="auth-layout">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
            <Chatbot />
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
