import React from "react";
import ReactDOM from "react-dom/client";  // react-dom yerine react-dom/client import edilmelidir
import { ThemeProvider } from './contexts/ThemeContext.js';
import App from "./App.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);